 // user Video
             .userVideo{
                position:absolute;top:10px;left:10px;z-index: 9;
                width:160px;height:130px; @include transition(all 0.3s);margin-bottom: 10px;    
                 img,video{
                 width:160px;height:130px; @include transition(all 0.3s);border-radius:4px;      
                    }
                    video{width:auto;}
                    .defailtImage{
                        display:none; 
                    }
                ul{
                    position:absolute;bottom:10px;left:0px;width:100%; padding:0 10px; 
                    li{
                        a{
                            height:30px;width:30px;border-radius:50%;background-color:rgba(map-get($theme, black-2b3), 0.6);
                            display:block;text-align:center;padding:3px 0;  
                            color:map-get($theme, white);font-size: 22px;  
                            &.active{
                                background-color:map-get($theme, blue); 
                            }
                            &.videoFullscreen{
                               background-color:map-get($theme, white);color:map-get($theme, black-2b3);  
                            }
                        }
                        &:not(:last-child){
                            a{margin-right:10px;}  
                        }
                    }
                }
                &.video-off{ 
                    .defailtImage{
                        display:block;
                     }
                     .videoImage{
                        display:none;
                     }
                        ul{  
                            li{
                                .videoFullscreen{
                                    display:none;
                                }
                            }
                        }
                    }
                     &.videoScreen-big{
                          width:260px;height:208px; 
                      img,video{
                          width:260px;height:208px; 
                      }   
                      video{width:auto}
                     }
                     &.videoScreen{
                         width:calc(100% - 10px);left:5px;top:5px;z-index:8;height:100%; 
                         video{
                                 position: absolute;left: 0;right: 0;height: 100%;width: 100%;
                         }
                     }
                }