

// container

.container{
	@media(min-width:1440px){
		max-width:1400px;
	}
}

.chatmdBtn{
	height:40px;width:40px;border-radius: 50%;background-color:map-get($theme, blue);color:map-get($theme, white);
	position: fixed;left:15px;bottom:100px;align-items:center;justify-content:center; 
	z-index:1001;font-size:22px;
	.icon-close{
		font-size: 30px; 
	}
	&:hover{
		color:map-get($theme, white);
	}
}

  @include media-breakpoint-down(md){
            .main-content{
                &.chatbarOpen{
                    .mainWrapper{
                    &__left{
                        left:0;
                    }
                }
                }
            }
        }
	.textdropdown{ 
			min-width:365px;border:1px solid map-get($theme, grey-c3c);border-radius:40px; 
			transform:inherit !important;padding:3px 15px;
			margin-top:12px;z-index:999;background-color:map-get($theme, white);
			display: none;
			ul{
				display:flex;align-items:center; 
				li{ 
					select{
						height:25px;width:45px;position: relative;top: 5px;  
					}
					a{
						padding:3px;border-radius:2px;padding:5px 7px;color:map-get($theme, black-505);display:block;
						i{
					font-size:26px;display:inline-block;vertical-align:middle;
						&.icon-crop_free_active{
							font-size:18px;    
						}
					}
					}								
					&.selectfont{
						height:33px;padding-right: 6px;
					}
					&.borderleft{
						position:relative;
						&:before{
							content:"";position:absolute;left:-9px;width:1px;height:calc(100% + 7px);
							background-color:map-get($theme, grey-c3c);top:-4px;
						}
					}
				}
			}
		}

//side bar loader
// .sideBarLoader{height: 100vh;}
//page loader
.pageLoader {position: fixed;top: 0; right: 0;left: 0; bottom: 0; width: 100%; height: 100%;
	background: rgba(map-get($theme, black-000),0.5);color:map-get($theme, white);z-index: 9999;}
	
// alert
.alert {
    border-width:0 3px 0 0px;border-style:solid;padding:10px 10px 7px;border-radius:0px;
    margin-bottom:0px;text-align:center;font-size:15px;
    &.alert-danger {
        color: #a94442;background-color: #f2dede;border-color: #A94442;
    }
    &.alert-success {
        color:#3c763d;background-color:#dff0d8;border-color:#59935A;
    }
    &.alert-info {
        color:#31708f;background-color:#d9edf7;border-color:#4C8BAA;
    }
    &.alert-warning {
        background-color:#F6F2DD;border-color:#AF9260;color:#8A6D3B;
    }
    @include media-breakpoint-down(sm){font-size:14px;}
}

// for tooltip
.__react_component_tooltip{
	padding:7px 10px 5px;white-space:nowrap;z-index:1001;  
}

@include media-breakpoint-down(lg){
	// // Defalt scrollbar
     ::-webkit-scrollbar { 
     width:3px;height:3px; 
     }
     ::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.2);
     }
     ::-webkit-scrollbar-thumb {
     background-color:map-get($theme, grey-818); 
     outline: 1px solid map-get($theme, grey-c3c); 
     }
}

// for page loader
.loadingPage{
	height:100vh;background-color:rgba(map-get($theme,blue),0.6);
	.spinner-grow{
       color:#fff;width:3rem;height:3rem;
	}
	h2{font-size:20px;margin:10px 0 0 10px; color:#fff;}
	@include media-breakpoint-down(md){
		h2{font-size:16px;margin:5px 0 0 5px;}
	}
}

 @media screen and (min-width: 320px) and (max-width: 767px) and (orientation:portrait){
        .pageWrapper{ display:none; }
        .pageWrapper_message {
			 display:flex;height:100vh;
			h3{font-size: 16px;text-align:center;color:map-get($theme,blue);font-family:map-get($font,neo-md);}
			}
    }
    @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape){
	   .pageWrapper{ display:block; } 
	   .pageWrapper_message { display:none; }
    }