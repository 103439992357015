.modal{
	 ::-webkit-scrollbar { 
     width:4px;
     }
     ::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.2);
     }
     ::-webkit-scrollbar-thumb {
     background-color:map-get($theme, grey-818); 
     outline: 1px solid map-get($theme, grey-c3c); 
	 }
		// text-align: center;
		// padding: 0!important;
		// &:before {
		// content: '';
		// display: inline-block;
		// height: 100%;
		// vertical-align: middle;
		// margin-right: -4px;
		// }
	.modal-dialog{ 
		// display: inline-block !important;
		// text-align: left !important;
		// vertical-align: middle !important;
		// // &:before{display:inline-block;}
		&.w-570{
			max-width:570px;
		}
		.modal-header{
			.close{
				color:map-get($theme, grey-777);padding:15px 0 0;margin-left: 0px;opacity:1;  
				i{font-size:28px;}
				&:hover{
					color:map-get($theme, black-505);   
				}
			}
			.modal-title{
				font-size:18px;color:map-get($theme, black-505);margin:15px auto 0;    
			}
		}
		.modal-body{
           padding:25px; 
           .btn-row{
           	margin-top:20px; 
           }
		}
	}
	&.bootbox{
		.modal-dialog{
			max-width:302px;
		.modal-content{
		  border-radius:6px;border:0px;	
		  @include box-shadow(0,0,23px,rgba(#000, 0.2));
		}
		.modal-header{
			padding-bottom: 13px;
			.modal-title{
				margin-top: 3px;font-size: 16px;
			}
		}
		.modal-body{
			direction: rtl;
			padding:25px 20px;
			.bootbox-close-button{
				display:none;
			}
			.bootbox-body{
				font-size:18px;text-align:center;color:map-get($theme, black-505);
			}
		}
		.modal-footer{
			padding:0px;justify-content:center;border-top:0px;
			direction: rtl;border-radius:0px;
			.btn{
			   padding:8px 25px;width:50%;border-radius:0px;margin:0px;
			   &.btn-primary{
				   border-radius:0 0 0 6px;
			   }
			   &.btn-dark{
				   border-radius:0px 0 6px 0px;background:transparent;color:map-get($theme, black-505);
				   border-color:map-get($theme, grey-dfe);background-color:map-get($theme, grey-dfe);
				   border-top-color:map-get($theme, grey-b9c);
			   }
			}
		}
		}
			&.presenterSession{
			.modal-dialog{
				margin:15px auto;
				    display: flex;
					align-items: center;
					min-height: calc(100% - 1rem);
					@media (min-width: 576px){
							min-height: calc(100% - 3.5rem);
						}
				.modal-footer{
					padding-bottom: 15px;
					.btn{
					&.btn-primary{
						border-radius:6px;width:90px;
					}
					}
				}
			}
		}
		@include media-breakpoint-down(md){
			.modal-dialog{
				max-width:245px;margin:0 auto;
				.modal-body{
					padding:15px 15px;
					.bootbox-body{
						font-size:15px;
					}
				}
				.modal-footer{
					.btn{
					padding:6px 25px;font-size: 13px; 
					}
				}
			}
			&.presenterSession{
			.modal-dialog{
				.modal-footer{
					.btn{
					&.btn-primary{
						width: auto;padding: 7px 25px 4px;
					 }
					}
				}
			}
		}
		}
	
	}
}
@import 'setting.modal'; 
@import 'add.modal'; 
@import 'extend-time-modal';

.sessionout-modal{
	.modal-body{ 
		p{font-size:16px;color:map-get($theme, 505);margin:20px 0 30px; } 
	}
}

.request-modal{
	.modal-body{
		h3{font-size:18px;color:map-get($theme, grey-505);margin:20px 0 10px;}
		p{font-size:16px;color:map-get($theme, grey-777);margin-bottom: 30px; } 
	}
}