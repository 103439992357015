.chatSidebar{
    position:relative;  
    .tab-content{height:100%;.tab-pane{height:100%;}}
    .attendelistloader{
         height:calc(100vh - 240px);
    }
     &__tabs{
     	margin-bottom: 10px;  
     	>.nav-item{
     		width:50%;
     		 .nav-link{
     		 	height:46px;font-size:16px;font-family: map-get($font, hrg);color:map-get($theme, black-505);border-radius:0px;  
     		 	background-color: map-get($theme, grey-dfe);display:flex;justify-content:center;align-items:center;border:0px; 
     		 	border-bottom:1px solid map-get($theme, grey-c3c);
     		 	.count{font-size:13px;}
     		 	&.active{
     		 		background-color:transparent;color:map-get($theme, black-505);border-bottom:0px; 
     		 		.text{
     		 			font-family: map-get($font, neo-md)
     		 		}   
     		 	}
     		 }
     		 &:last-child{
     		 	.nav-link{
     		 		border-left:1px solid map-get($theme, grey-c3c); 
     		 	}
     		 }
     	}
     }

     .searchForm{
     	margin:15px 0 20px;    
     	.form-control{
     		padding-left:40px;
        &.focused{
          ~button{display:none;} 
        }
     	}
     	.btn{
     		background-color:transparent;@include absolutecenter(y);left:15px; height: 19px; font-size: 19px;
     		color:map-get($theme, black-505);
     		&:focus{
     			background-color:transparent;; 
     		}  
     	}
     }
     h3{font-size:15px;font-family: map-get($font, hrg);color:map-get($theme, black-505);margin:15px;}  
     .attendancelisting{  
         height:calc(100vh - 181px);overflow-y:auto;padding-bottom:10px;
         scrollbar-width:none;

      }
     .attendanceList{
     	  margin-bottom:0px;padding:0px;  
     	>li{
     		display:flex;justify-content:space-between;padding:10px 15px 15px; 
     		align-items:center;position:relative;
          &:after{
            content:"";@include absolutecenter(x);width:calc(100% - 30px);height:1px;
            border-bottom: 1px dotted #b9c2cb; bottom:0px;  
          } 
          &.active{
            background-color:map-get($theme, white); 
            &:after{
              width:calc(100% - 0px);
            }
          }
     	}
     	&__left{
     		height:25px; 
     			li{ 
     				>a{
	 				      display:inline-block;color:map-get($theme, black-505); 
                height: 30px;width:30px;border-radius:50%;text-align:center;padding:3px;
                border:1px solid transparent;  
    		 				i{
    		 					font-size:22px;
    		 				}    
                              &:hover,&[aria-expanded="true"]{
                              background-color:map-get($theme, white);border-color:map-get($theme, grey-777);  
                              }
                              &.active{ 
                              background-color:map-get($theme, blue);border-color:map-get($theme, blue);color:map-get($theme, white);
                              }
                              &.disabled{
                                   pointer-events: none;opacity:0.5;
                              }
	 			    }
            .dropdown-menu{
              min-width: 100px;border:0px;@include box-shadow(0,0,3px,rgba(map-get($theme, black-505), 0.3));  
              .dropdown-item{ 
                text-align:right;font-size:14px;padding:3px 10px;color:map-get($theme, grey-777);  
                &:hover,&:focus{
                  background-color:rgba(map-get($theme, grey-c3c), 0.4);color:map-get($theme, black-505);
                }
              }
            }
     		}
 		}
 		&__right{
 			p{font-size:15px;font-family: map-get($font, hrg);color:map-get($theme, black-505);height:18px;} 
 		}
 		&--forAll{
 			>li{
 				padding:15px 15px;
        &:after{
          display:none;
        }
 			}
 		}
    }
    .chatBox{
          &__options{
               padding:15px;
               >li{
                    margin-right:0;
                    a{
                         border:1px solid map-get($theme, grey-c3c);background-color:map-get($theme, white);
                         display:inline-block;color:map-get($theme ,  black-505);border-radius:50rem;padding:5px 8px 5px 5px;
                         min-width:60px;text-align:center;font-size:14px;line-height:27px; 
                         @include box-shadow(0,2px,2px,rgba(map-get($theme, black-000), 0.1));
                         span{
                             min-width:34px;height:28px;line-height:25px;border-radius:50rem;
                             background-color:rgba(map-get($theme, grey-c3c), 0.4); 
                         }
                         &.active{
                              background-color:map-get($theme , blue);
                              border-color:rgba(map-get($theme, grey-c3c), 0.4);color:map-get($theme , white);                              
                              span{background-color:map-get($theme, white);} 
                              &:hover{
                                  background-color:map-get($theme , blue);
                                  border-color:rgba(map-get($theme, grey-c3c), 0.4);color:map-get($theme , white);                              
                                  span{background-color:map-get($theme, white); color: #212529;} 
                              }
                         }
                         &:hover{
                              color:map-get($theme , blue);
                              border-color:rgba(map-get($theme, blue), 0.4);background-color:map-get($theme , white);
                              span{background-color: rgba(map-get($theme,blue), 0.1);color:map-get($theme , blue);} 
                         }
                         // &:hover{
                         //      background-color:map-get($theme , white); 
                         //      border-color:#dac9ff;color:#610dd6;
                         //      span{
                         //           background-color:#efe8fa;color:#610dd6;
                         //      }
                         // }

                    }
               }
          }
          .listLoaders{
               height:calc(100vh - 178px) !important;position:absolute;width:100%;background-color:#edf0f4;
               z-index:101;
          }
          .listingWrapper{
            height:calc(100vh - 242px);overflow-y:auto;padding:0 10px 10px;overflow-x:hidden; 
            scrollbar-width: none;position: relative;
          //   .listLoaderoverflow{
          //        position: absolute;width: 100%;top:0;left: 0;z-index:10;height: 100%;background:#edf0f4;
          //   }  
            &--studentsWrapper{
              height:calc(100vh - 365px);    
            }
            &.requestList{
                  height:calc(100vh - 190px);
            }
          }
          &__listing{
               padding-bottom: 15px;
               >li{
                    // display:flex;justify-content: flex-end;
                    &:not(:last-child){margin-bottom:8px;}
                        .replyBox{
                         &__cnt{
                         border-radius:10px 10px 10px 24px;margin-left:50px;background-color:map-get($theme, blue);
                         padding:15px 15px 15px 20px;display: inline-block;color:map-get($theme, white);position: relative;
                         margin-top:-10px;
                         }
                    }
               }   
               &__msgBox{
                    padding:10px 10px 10px 20px;background-color:map-get($theme, white);border-radius:10px 10px 10px 24px;
                    position:relative;margin-left:50px;border-right:6px solid map-get($theme, white);display: inline-block;
                    $parent:chatBox__listing__msgBox;
                    @include box-shadow(-1px , 1px , 1px , rgba(0,0,0,0.1));
                    span{font-size:12px;color:map-get($theme, black-505);font-family:map-get($font, neo-md);}
                    p{font-size:15px;color:map-get($theme, black-000);font-family:map-get($font, hrg);direction:rtl;}
                    &__share{
                        position:absolute;left:-36px;opacity:0;@include transition(all 0.5s);top:0;
                        &.active{
                            opacity:1;
                        }
                         >li{
                              margin-right:0;
                              &:not(:last-child){margin-bottom:8px;}
                              &:nth-child(2n) {
                                  a{
                                    &.active{
                                        border:1px solid map-get($theme, black-505);;
                                        span{color:map-get($theme, black-505);}
                                    }
                                  }
                              }
                             a{
                                   width:24px;height:24px;background-color:map-get($theme, white);border-radius:50%;line-height:24px;
                                   border:1px solid transparent;
                                   span{color:map-get($theme, grey-c3c);font-size:20px;}
                                   &:hover{span{color:map-get($theme, black-505);}}
                                   
                             } 

                              .checkbox {
                                label {
                                  display: flex;
                                  align-items: center;margin-bottom:0;
                                  cursor: pointer; 

                                  .checkbox-icon-wrapper {
                                    position: relative;display: inline-block;float: left;
                                    width: 24px;height: 24px;background-color:map-get($theme, white);border-radius: 50%;border: 1px solid transparent;
                                    .checkbox-icon {
                                        font-size:21px;color:map-get($theme, grey-c3c);
                                    }
                                  }

                                  input[type="checkbox"] {
                                    display: none;
                                    &:checked + .checkbox-icon-wrapper > .checkbox-icon {
                                      opacity: 1;
                                        &:before{content: "\e942";}color:#ffc800;
                                    }
                                    &:checked + .checkbox-icon-wrapper{
                                      border: 1px solid #ffc800;
                                    }
                                  }
                                }
                              }

                         }
                    }
                    &:hover{
                         .chatBox__listing__msgBox__share{
                              opacity:1;
                         }
                    }
                    &.fileBox{
                         border-radius:10px;display:block;margin-left:0px;padding:10px;padding-right:4px;
                    }
                    &--favorite{
                         border-color:#ffc800;
                         .#{$parent}__share{ 
                              opacity:1;
                              >li{
                                   &:not(:last-child){margin-bottom:8px;}
                                   &:first-child{
                                       a{
                                             border-color:#ffc800;
                                       }
                                        span{color:#ffc800;}
                                   }
                             }
                         }
                    }
                    .documentBox{
                         &__left{
                              // height:25px;
                           a{
                              font-size:24px;color:map-get($theme, black-505);
                              img{width: 23px;}
                           }
                         }
                         &__right{
                           img{margin-left:10px;}
                           .filename{
                             line-height:23px;
                           }
                         }
                    }
               }
               &__msgBox--reply{
                   position: fixed;bottom:66px;border-radius: 0;width: 100%;left: 1px;margin-left:0;padding:10px;
                   @include box-shadow(0px , 0px , 0px , rgba(0,0,0,0));width:330px;
                   display:flex;justify-content:space-between;border-top:1px solid map-get($theme, grey-c3c); 
                   .CloseBtn{
                         a{
                              span{color:map-get($theme, grey-b9c);font-size:24px;}
                              &:hover{
                                   span{color:map-get($theme, black-505);}
                              }
                         }
                    }
               }
               // &__msgBox--students{ 
               //  bottom:64px;  
               // }
               &__msgBox--Creply{
                    // padding-bottom:20px;margin-bottom:40px;
                
               }
               &__msgBoxInfo{
                    .top{
                         padding:15px 6px 15px 10px;border-radius:10px 10px 0 0;
                         font-size:14px;color:map-get($theme, white);background-color:map-get($theme, blue);
                         span{font-size:24px;}
                         &.topSend{
                              background-color:#e3cef2;border:1px solid #c7a0db;color:map-get($theme, blue);
                              border-radius:10px;display:flex;justify-content: flex-end;
                              // p{white-space:nowrap;}
                         }
                    }
                    .bottom{
                         border:1px solid map-get($theme, grey-c3c);border-radius:0 0 10px 10px;
                         >li{
                              a{color:map-get($theme, blue);line-height:normal;padding:2px 5px;}
                              &:not(:last-child){border-right:1px solid map-get($theme, grey-c3c);}
                         }
                    }
               }
          }
          .requestWrapper{
               margin:15px 0 10px; 
               a{
                    display:inline-block;color:map-get($theme, white);background-color:map-get($theme, blue);
                    padding:13px 20px 10px;border-radius:30px;   
                    span{font-size:16px;}
                    i{font-size:24px;margin-left: 10px;}
                    &.watingBox{
                         background-color:map-get($theme, black-505);
                    }
                    &.acceptBox{
                         background-color:map-get($theme, black-505);
                    }
               }
          }
          &__footer{
               border-top:1px solid map-get($theme, grey-c3c);
               .emoji-picker-icon{
                    right: inherit;left: 10px;top: 18px;font-size: 24px;
                         &:hover{
                              color:#a3acb8; 
                         }
               }
               .emoji-menu{
                    right: 10px;top: -230px;width: 310px;border:0px;box-shadow:none;
               }
               .sendMessage{
                    position: absolute;top: 18px;left: -16px;background: transparent;
                    border: 0px;font-size: 20px;color:map-get($theme, black-505); 
                    &:hover{
                         color:#a3acb8; 
                    }
               }
               .form-group { direction: rtl;
                .form-control{border:0;padding-right:15px;height:64px; padding-left: 50px; width: 100%; 
                direction: rtl;}
                }
               &__option{
                    min-width:80px;padding-left:7px; 
                    li{
                         &:not(:last-child){margin-right:10px;}
                        a{
                             color:map-get($theme, black-505);
                              span{font-size:24px;}
                              &:hover{
                                   color:#a3acb8;
                              }

                         } 
                    }
                    &+.form-group{
                        .emoji-picker-icon{
                             left: -43px;                                  
                         }
                         .form-control{padding-left:0;width:250px;}
                    }
               }
              
          }
     }


     // for students video view css
     .videoView{ 
      position:relative;margin-bottom: 10px;height:246px;overflow: hidden;  
      img{
        width:100%;height:225px; 
      }
      &__videoIcon{
        height:40px;width:40px;background-color:map-get($theme, white);color:map-get($theme, black-505);
        font-size:26px;@include absolutecenter(x);bottom:20px; padding:7px 0;  
        &.active{
             background-color:map-get($theme, blue);color:map-get($theme, white);
        } 
      }
     }
     
     // // Defalt scrollbar
     ::-webkit-scrollbar { 
     width:0px;height:4px; 
     }
     ::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.2);
     }
     ::-webkit-scrollbar-thumb {
     background-color:map-get($theme, grey-818); 
     outline: 1px solid map-get($theme, grey-c3c); 
     }

     @media(max-width:1599px){
          .chatBox{
               &__listing{
                   &__msgBox--reply{
                       width:278px;bottom:69px; 
                   }
               }
               &__options{
                    padding:15px 10px;
                    >li{
                          a{
                               min-width:45px;
                              span{
                                     min-width:20px;height:25px;line-height:22px;  
                              }
                          }
                    }
               }
               &__listing{
                  &__msgBoxInfo{
                      .top{
                        &.topSend{
                             display:flex;align-items:center;
                        }
                      }
                  }
               }  
               &__footer{
                    .emoji-menu{
                         width:260px; 
                    }
                    &__option{
                         + .form-group{
                              .form-control{
                                   width:200px;
                              }
                         } 
                    }
               } 
          }
     }
     @include media-breakpoint-down(lg){
          .chatBox{
               &__listing{
                   &__msgBox--reply{
                       width:248px;bottom:75px;  
                   }
               }
               &__options{
                    padding:8px 0;margin:0 10px 10px !important;
                    white-space: nowrap;overflow-x: auto;
                    >li{
                          a{
                               min-width:45px;
                              span{
                                     min-width:20px;height:25px;line-height:22px;  
                              }
                          }
                          &:not(:last-child){
                               margin-right:6px;
                          }
                    }
               }
               &__listing{
                    &__msgBox{
                       padding:5px 10px 5px 15px;  
                    }
                  &__msgBoxInfo{
                      .top{
                       padding:8px 6px 8px 10px; 
                      }
                  }
               }  
               &__footer{
                    .emoji-menu{
                         width:235px;right:8px;
                    }
                    &__option{
                         + .form-group{
                              .form-control{
                                   width:165px;
                              }
                         } 
                    }
               } 
          }
     }
     //  @include media-breakpoint-down(md){
     //      .chatBox{
     //           &__listing{
     //               &__msgBox--reply{
     //                   width:100%;bottom:75px;  
     //               }
     //           }
     //           &__options{
     //                padding:8px 0;margin:0 10px 10px !important;
     //                white-space: nowrap;overflow-x: auto;
     //                >li{
     //                     flex:0 0 33.3%;max-width:33.3%;padding:0 10px; 
     //                      a{
     //                           min-width:100%;
     //                      }
     //                      &:not(:last-child){
     //                           margin-right:0;
     //                      }
     //                }
     //           }
     //           &__listing{
     //                &__msgBox{
     //                   padding:8px 10px 8px 15px;  
     //                }
     //              &__msgBoxInfo{
     //                  .top{
     //                   padding:8px 6px 8px 10px; 
     //                  }
     //              }
     //           }  
     //           &__footer{
     //                .emoji-menu{
     //                     width:235px;right:8px;
     //                }
     //                &__option{
     //                     + .form-group{
     //                          .form-control{
     //                               width:165px;
     //                          }
     //                     } 
     //                }
     //           } 
     //      }
     // }
} 




