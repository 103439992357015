 // admin chat
             .adminChat{
                 border:1px solid map-get($theme,  grey-c3c);position:absolute;bottom:142px;left:10px;    
                 border-radius:6px;width:240px;display:none;z-index:101;overflow: hidden; 
                //  min-height:205px;
                 .listLoaders{
               height:199px !important;position:absolute;width:100%;background-color:#edf0f4;
               z-index:101;
          }
                 .chatList{
                     max-height: 200px;overflow-y: auto;overflow-x: hidden;
                     scrollbar-width: none; 
                      
                 }
                 // // Defalt scrollbar
                    ::-webkit-scrollbar { 
                    width:0px;height:0px;
                    }
                    ::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.2);
                    }
                    ::-webkit-scrollbar-thumb {
                    background-color:map-get($theme, grey-818); 
                    outline: 1px solid map-get($theme, grey-c3c); 
                    }
                 &__chat{
                    background-color:#f0f4f7;border-bottom:1px solid map-get($theme,  grey-c3c);padding:15px;
                    border-radius:0;
                    &__userImg{
                        height:35px;width:35px;margin-left:10px
                    }
                    &__name{
                        font-family:map-get($font, hrg);color:map-get($theme, grey-818);;
                        h4{font-size:14px;margin-bottom: 2px;}  
                        span{font-size:12px;}
                    }
                    p{font-size:14px;color:#17181a;line-height:22px;margin-top:8px;word-break: break-all;}    
                 }
                 &__reply{ 
                    textarea{
                        height: 45px;padding: 12px 15px 5px;font-size: 14px;border-radius: 0;   
                    }
                }
                &--replyBox{
                    min-height: auto; 
                }
            }