.studentPage{
        .chatSidebar{
           .chatBox{
               .listLoaders{
                   height: calc(100vh - 307px) !important;
               }
             .listingWrapper{
                    height: calc(100vh - 446px); 
                    &.loaderHeight{
                         height: calc(100vh - 368px);  
                    }
             }
           } 
        } 
        .mainWrapper{
            &__right{
                .white-board{
                    height:calc(100vh - 51px);
                    .slideControl{
                        bottom:15px;   
                    }
                }
            }
        }
        @include media-breakpoint-down(md){
            .chatSidebar{
                .videoView{
                    height:130px;width:100%;z-index:100;
                    @include transition(all 0.3s);
                    // box-shadow: -3px 7px 15px #efecec;
                    .videoFullBtn{
                            height:30px;width:30px;border-radius:50%;display:flex;align-items:center;justify-content:center;
                            background-color:map-get($theme,white);color:#000;position: absolute;bottom: 11px;
                            right: 10px;font-size: 20px;font-weight: bold;cursor: pointer;
                            .icon-fullscreen-exit{
                                font-size:25px; 
                            }
                        }
                    }
                .chatBox{
                    .listLoaders{
                         height: calc(100vh - 141px) !important;
                         z-index:1001;
                        }
                    .requestWrapper{
                         display:none;
                    }
                    .chatDownbtn{
                        height:30px;width:30px;border-radius:50%;background-color:map-get($theme, blue);color:map-get($theme, white);
                        font-size:14px;@include absolutecenter(x);bottom:57px;display:flex;justify-content:center;
                        i{transform:rotate(-90deg);width: 1px;height: 15px;margin-top: 14px;}
                    }
                    .listingWrapper {
                        height: calc(100vh - 182px);
                    }
                    &__listing{
                        padding-bottom: 0;
                        &__msgBox{
                            margin-left:36px;
                            p{font-size:13px;}
                            &.fileBox{
                                margin-left: 0;
                            }
                            >div{
                                span{
                                font-size:11px;
                                ~div{font-size: 13px;word-break: break-all;}
                            }
                            }
                            &--reply{
                                padding:4px 8px;bottom:41px;width:198px; margin-left: 0;
                                z-index: 1;
                                .CloseBtn{
                                    a{
                                        span{font-size: 22px;}
                                    }
                               }
                            } 
                        }
                        > li{
                            .replyBox{
                                &__cnt{
                                    padding:8px 10px 8px 15px;font-size: 13px;margin-top: -6px;
                                }
                            }
                        } 
                    }
                    &__footer{
                        position:relative;z-index:999;
                        .form-group{
                            .form-control{
                                height:40px !important;padding:9px 10px 0 40px; font-size: 14px; 
                            }
                        } 
                        .emoji-menu {
                            width: 185px;
                            right: 6px;top:-186px;  
                            .emoji-items-wrap{
                                height:132px; 
                            }
                        }
                        .emoji-picker-icon{
                          left: 8px;top: 9px;font-size: 22px;z-index:100;
                        }
                    } 
                }
                &.sidebarFull{
                 .chatBox{
                         .listingWrapper{
                           height:calc(100vh - 42px);  
                         }
                     }
                }
            } 
            .mainWrapper{
                &__left{
                    max-width:200px;flex:0 0 200px;width:200px;height:100vh;
                    @include transition(all 0.3s);position:absolute;left:0px;top:0px;
                }
                &__right{
                    width:calc(100% - 200px);height:100vh;@include transition(all 0.3s);
                    margin-left:200px;
                    .overlayHideShowBtn{
                       position:absolute;bottom:20px;left:10px;z-index: 101;background-color:map-get($theme,blue);
                       height:30px;width:30px;border-radius:50%;line-height:31px;text-align:center;display:none;
                       img{width:18px;}
                    }
                     // // Defalt scrollbar
                     #canvas_scroll_1582694642141{
                        ::-webkit-scrollbar {  
                          width:10px;height:10px; 
                        }
                     }
     
                    .slideControl{
                          .pagination{
                              width:270px;
                              .page-item{
                                  .page-link{
                                        padding: 4px 6px 3px;min-height: 34px;font-size:14px;
                                      &.zoomSlider{
                                          font-size: 23px;
                                        .customRange {width:52px;}
                                      }
                                      &.slideInfo{padding-top:10px;}
                                      [class*=" icon-arrow"],[class^="icon-arrow"]{
                                       line-height:25px;min-height: 25px;min-width:23px;
                                      }
                                  }
                              }
                          }
                    } 
                    .white-board{
                        height:100vh;border:0; 
                    }
                    .mobileView{
                        position: absolute;top:0px;left:0px;width:100%;padding: 10px 15px;
                        height:100vh;z-index:100;
                        &:before{
                            content:"";position:absolute;top:0px;left:0px;width:100%;height:100%;
                            background-color:rgba(#8c9196, 0.6);
                        }
                        &__top{
                                color: #fff;position: relative;z-index: 100;
                            label{font-size: 20px;}
                            span{font-size:17px;}
                        }
                    }
                    .mobileViewBottom{
                            position:absolute;bottom:0;left:0px;padding:10px;width:100%;z-index: 101;display:flex;
                            &__left{
                                a{
                                    background-color:map-get($theme, blue);color:map-get($theme, white);
                                    font-size:15px;border-radius:20px;padding:9px 12px 7px;
                                    @media(max-width:500px){
                                        font-size: 13px;
                                    }
                                    i{
                                        font-size: 20px;margin-left: 3px;margin-top: -3px;display: inline-block;
                                    }
                                    &.watingBox{
                                            background-color:map-get($theme, black-505);
                                        }
                                        &.acceptBox{
                                            background-color:map-get($theme, black-505);
                                        }
                                }
                            }
                            &__right{ 
                                @include transition(all 0.3s);
                                a{
                                    height:30px;width:30px;border-radius:50%;background-color:rgba(43,49,56,0.6);
                                    color:map-get($theme, white);display: inline-block;font-size:19px;
                                    justify-content:center;
                                    &.active{
                                       background-color:map-get($theme, blue);
                                    }
                                    &:not(:last-child){
                                        margin-right:10px;
                                    }
                                }
                            }
                        }
                        .multipleStream{
                            &__left{
                                z-index:99;right:10px;left:inherit;display:none;
                               .userVideo{
                                   height:80px;width:110px;margin-left: auto;
                                //    position:fixed !important;left:206px;
                                   video{height:80px;}
                                   &__bottom{
                                       justify-content:center;
                                       li{
                                           &:not(:last-child){
                                               a{margin-right: 5px;}
                                           }
                                           &:nth-child(2){
                                               a{margin-right: 0;}
                                           }
                                           &:last-child{display:none;}
                                       }
                                   }
                               }
                               .requestTime{
                                   padding:6px 10px 10px;width:165px;margin-bottom:7px;
                                    li{
                                         span{
                                             font-size: 13px;
                                         }
                                         h4{
                                             span{font-size: 13px;}
                                         }
                                         a{
                                             i{font-size: 23px;}
                                         }
                                    }
                               }
                            } 
                            &__right{
                                z-index:99;right: inherit;left: 6px;display:none;
                                .userVideo{
                                    width:90px;height:80px;margin-bottom:7px;
                                     video{
                                         width:auto;height:80px;
                                     }
                                }
                            }
                        }
                }
         }
          &.videoFullView{
                .chatSidebar{
                     .videoView{
                            height: 100%;position: fixed;top: 0px;left: 201px;width: calc(100% - 201px);z-index: 100;
                            box-shadow:none;background:transparent;
                            video{
                                height: 100%;object-fit: fill;
                            }
                           .videoOverlay{
                            position:absolute;top:0px;left:0px;width:100%;height:100%;
                            background-color:rgba(#8c9196, 0.6); 
                           }
                        }
                         .chatBox{
                         .listingWrapper{
                           height:calc(100vh - 42px);  
                         }
                     }
                }
                .mainWrapper{
                     .mobileView{
                         height:auto;
                         &:before{display:none;}
                     }
                      .mobileViewBottom{
                             width:calc(100% - 41px);
                         }
                }
            }
            &.hideSidebar{
                .mainWrapper{
                      &__left{
                          left:-210px;
                      }
                      &__right{
                          width:100%;margin-left:0;
                      }
                }
            }
            &.overlayScreen{
                .mainWrapper{
                   .mobileView,.mobileViewBottom{
                      display:none;
                     } 
                     &__right{
                         .overlayHideShowBtn{
                             display:block;
                         }
                     }  
                }
                &.videoFullView{
                    .chatSidebar{
                     .videoView{
                         z-index:98;
                        .videoFullBtn,.videoOverlay{
                           display:none !important;
                        }
                    }
                  }
                }
            }
        }
    }