 .form-control {
        height:48px;
        color: map-get($theme, black-505);
        font-size: 15px;
        border-radius:4px; 
        border-color:map-get($theme, grey-c3c);
        padding: 12px 10px 8px; 
    &:focus {
        outline: none;
        box-shadow: none;
        border-color:map-get($theme, grey-c3c);   
    }
    &[readonly]{
     background-color:white;
    } 
    &::-webkit-input-placeholder{ /* Edge */
        color: map-get($theme, grey-818);
      }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: map-get($theme, grey-818);
    }

    &::placeholder {
      color: map-get($theme, grey-818); 
    }
}

.form-group{
  position:relative;
  .valueRefresh{
    font-size:25px;color:map-get($theme, black-505);@include absolutecenter(y);left:10px;height:25px; 
    opacity:0;@include transition(all 0.3s);
  }
  .focused{
    ~.valueRefresh{
      opacity:1;  
    }
  }
}

// .CUSTOM RADIO BUTTON
.custom-radio{
    position:relative;padding-right:50px;padding-left: 0px; 
     label{
        margin-bottom:0px;text-align:right;display:block;cursor:pointer;   
         &:before{
        content:"\e918";@include absolutecenter(y);right:0px;font-family: 'icomoon' !important;font-size:32px; 
         color:map-get($theme, grey-b9c);width:32px;text-align:center;  
       } 
       h5{
        font-size:14px;font-family:map-get($font, neo-md);color:map-get($theme, black-505);margin-bottom:3px;
         }
       p{
        font-family:map-get($font, hrg);color:#777d84;font-size: 12px;
       }  
   }

   .custom-control-input{ 
    &:checked{
        ~label{
            &:before{
                content:"\e916";font-size:26px; 
            }
        }
    }
   }
}

.custom-checkbox{
    position:relative;padding-right:34px;padding-left: 0px;  
     label{
        margin-bottom:0px;text-align:right;display:block;cursor:pointer;   
         &:before{
        content:"\e95d";@include absolutecenter(y);right:0px;font-family: 'icomoon' !important;font-size:22px; 
         color:map-get($theme, grey-777);width:30px;text-align:center;    
       } 
       span{
        font-family:map-get($font, hrg);color:map-get($theme, grey-777);font-size: 14px; 
       }  
   }

   .custom-control-input{
    &:checked{
        ~label{
            &:before{
                content:"\e915"; 
            }
        }
    }
   }
}