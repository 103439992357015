.footerSection{
	position:absolute;z-index:9;width: 100%; 
   bottom:0px;@include transition(all 0.3s); 
	&__arrow{
			display:inline-block;color:map-get($theme, white);background-color:map-get($theme, black-2b3);
			padding:1px 35px 0;height: 30px;border-radius:5px 5px 0  0;
         @include absolutecenter(x);top:-30px;   
			i{font-size:28px;}  
			&:hover{
            color:map-get($theme, white); 
         }
         @include media-breakpoint-down(lg){
            padding:1px 25px;height:28px;top:-26px;    
         }
		}

	&__inner{
         background-color:map-get($theme, black-2b3);padding:25px 15px 25px 25px;height:130px;
         display:flex; 
         &__icon{
         	font-size:65px; height: 65px; color:map-get($theme, grey-b9c);
         	&:hover{
				color:map-get($theme, white);   
			}
         }
         &__right{
         	li{
         		text-align:center;@include transition(all 0.3s);vertical-align:top;position: relative;  
         		span{font-size:14px;font-family:map-get($font, hrg);color:rgba(map-get($theme, white), 0.7);
              margin-top:3px; max-width: 96px; @include text-overflow();}   
         		label{
         			height:58px;width:96px;border:1px dashed map-get($theme, grey-b9c);cursor:pointer;position:relative; 
         			i{font-size:30px;color:map-get($theme, grey-b9c);}  
                  .count{
                     height:20px;width:20px;background-color:#ff8b0f;position:absolute;top:-15px;left:-10px;
                     font-size: 12px;padding: 2px 0;opacity:0;@include transition(all 0.3s);  
                  }
         			~span{
         				color:map-get($theme, grey-818);
         			}
                  &:hover{
                     background-color:rgba(#ff8b0f, 0.3);border-color:#ff8b0f; 
                     .count{
                        opacity:1;
                     } 
                  }
         		}

         		.box{
         			height:58px;width:96px;text-align:left; 
                  background-color:map-get($theme, white); 
                  opacity:0.7;@include transition(all 0.3s);  
         			img{
                     margin:-4px -1px 0;  
                     &.facebook-img{
                        width:20px;margin: 0px; 
                     }
                  }
               //  &.active{
               //       border:4px solid map-get($theme, blue);
               //   }
         		}
         		.delete{
                  font-size:14px;font-family:map-get($font, hrg);color:map-get($theme, grey-818);
                  position: absolute;left: 50%;transform: translateX(-50%);opacity:0;
         		}
         		&:hover, &.active{
         			margin-top:-10px;  
         			span{
         				color:map-get($theme, white);
         			}
                  a{opacity: 1;}
                  .box{opacity:1;}
               }
         	}
         }
      @media(max-width:1599px){
         &__right{
              li{
                label{
                  width:74px;height:54px;
                }
                span{font-size:13px;max-width:74px;} 
                .box{
                  width:74px;height:54px; 
                }
              } 
         }
      }
       @include media-breakpoint-down(lg){
          height:125px;padding:25px 15px 15px;
          &__icon{
             font-size:50px;height:auto;
          }
         &__right{
              li{
                label{
                  width:55px;height:40px; 
                }
                .delete{font-size:13px;} 
                span{font-size:13px;max-width:52px;} 
                .box{
                  width:52px;height:40px; 
                }
              } 
         }
      }
	}
}

.footerClose{ 
   .homePage{
    .mainWrapper{
      &__right{
         // .white-board{
         //   height:calc(100vh - 51px);      
         // }
         .white-board{ 
            .slideControl {
            bottom:45px;  
         }
         }
         .adminChat{
            bottom:10px; 
         }
      }
    }
  }
	.footerSection{     
      // bottom:-160px;
		&__arrow{ 
			i{
            display: inline-block;transform: rotate(-180deg);    
         }
      }
      &__inner{
         display:none;
      }
	}
}