.error_page{
	background-color:map-get($theme, white);
	#notfound {
	  position: relative;
		height: 100vh;
		.notfound {
			position: absolute;
			left: 50%;
			top: 50%;
			-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
		}
	}
	.notfound {
	  min-width: 520px;
	  width: 100%;
	  line-height: 1.4;
		text-align: center;
		.notfound-404 {
			position: relative;
			height: 200px;
			margin: 0px auto 20px;
			z-index: -1;
			h1 {
				font-size: 205px;  
				margin: 0px;
				// color: #211b19;
				text-transform: uppercase;
				position: absolute;
				left: 50%;
				top: 50%;
				-webkit-transform: translate(-50%, -50%);
					-ms-transform: translate(-50%, -50%);
						transform: translate(-50%, -50%);
				&.mainHeading{font-size:70px;} 	 	
			}
			h2 {
				font-size: 25px;
				font-weight: 400;
				text-transform: uppercase;
				color: #211b19;
				background: #fff;
				padding: 12px 20px 8px;
				margin: auto;
				display: inline-block;
				position: absolute;
				bottom: 5px;
				// @include box-shadow(0, 0, 10px, rgba(#ddd, 0.5));
			 left:50%;
			 transform: translateX(-50%);
			 min-width:450px;
			}

		
		}
		.btn-primary{
			padding: 14px 40px 13px;
		}

	}

@include media-breakpoint-down(lg){
	.notfound{
		.notfound-404{
			h1 {
				font-size: 170px;
				}
				h2{
					font-size:18px;min-width:340px;
				}
		}
		.btn-primary{
			padding: 12px 25px 10px;
		}	
	}  
}
@include media-breakpoint-down(md){
	.notfound{
		.notfound-404{
			height:173px;margin-bottom:10px;
			h1 {
				font-size: 125px;
				}
				h2{
					font-size:18px;bottom:18px;
				}
		}
	}  
}
@include media-breakpoint-down(sm) {
	.notfound{
		min-width: auto;
		.notfound-404{
		   margin-bottom:0px;
			h1 {
				font-size:50px;
				&.mainHeading{font-size: 30px;}
				}
				h2{
					font-size:13px;bottom:37px;min-width:275px;
				}
		}
	} 
	}

	@media (max-width: 480px) {
	  .notfound{
			.notfound-404{
				height:155px;
					h2{
				     font-size:14px;
					}
			}
		}
	}
}

