.btn:focus,.btn.active,input:focus,input:hover,button:focus,.btn:active,button:hover,button:active,button.active,.selectpicker:focus,a:focus,
.btn:active:focus {
    @include no-outline();box-shadow: none !important;
}
:focus {
    outline: none;
}

textarea{
    resize:none;
}
.error-msg{
    font-size:13px;color:red;display:block;text-align:right;margin-top: 3px;
}

// font and colors
.blue-color{color:map-get($theme, blue);}

.font-hrg{font-family:map-get($font, hrg) !important;}
.font-hbd{font-family:map-get($font, hbd) !important;}
.font-neomd{font-family:map-get($font, neo-md) !important;}

//background color
.bg-grey{
    background-color:#edf0f4; 
}
// custom scroll
.mCustomScrollbar{
    .mCSB_inside > {
        .mCSB_container {
            margin-right: 0px;
        }
    }
    .mCSB_draggerRail {
        background: transparent;
    }
    .mCSB_dragger_bar {
        background-color:map-get($theme, grey-c3c) !important; width: 2px !important; border-radius:6px;
    }
    .mCSB_scrollTools{
      width: 9px !important;
    }
}

// Ripple Effect
.ripple-effect-dark,
.ripple-effect {
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.ripple-effect span.ripple-overlay,
.ripple-effect-dark span.ripple-overlay {
    animation: ripple 0.9s;
    border-radius: 100%;
    background: #fff;
    height: 12px;
    position: absolute;
    width: 12px;
    line-height: 12px;
    opacity: 0.2;
    pointer-events: none;
    z-index: 0;
}

.ripple-effect-dark span.ripple-overlay {
    background: #000;
    opacity: 0.07;
}
//common padding for inner pages
main{
  &.inner-page{
    padding-bottom: 60px;
    @include media-breakpoint-down(md){
        padding-bottom: 40px;
    }
    @include media-breakpoint-down(sm){
        padding-bottom: 30px;
    }
  }
}
//loader for button


.alert {
  border-width: 0 0 0 3px;
  border-style: solid;
//   padding: 5px 10px;
  border-radius: 0px;
  font-size: 15px;
  @media(max-width: 767px) {
      font-size:14px;
  }
  &.alert-danger {
      color: #a94442;
      background-color: #f2dede;
      border-color: #A94442;
      text-align: center;
  }
  &.alert-success {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #59935A;
  }
  &.alert-info {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #4C8BAA;
  }
  &.alert-warning {
      background-color: #F6F2DD;
      border-color: #AF9260;
      color: #8A6D3B;
  }
}