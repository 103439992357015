header{
	.headerWrapper{
		padding:0px;//border-bottom:1px solid map-get($theme, grey-c3c);
		@include box-shadow(0,1px,2px,rgba(map-get($theme, black-000), 0.2)); position: relative; z-index: 9;  
		ul{
			font-size:0px;
			li{
				a{
                  padding:12px 15px;color:map-get($theme, black-505);display:block;
                  &:hover,&.active,&[aria-expanded="true"]{
                  	color:map-get($theme, blue);background-color:rgba(map-get($theme, blue), 0.2);  
                  }
                  &.border_right{border-right:1px solid map-get($theme, grey-c3c);}
                  i{
                  	font-size:26px;display:inline-block;vertical-align:middle;
                  	&.icon-crop_free_active{
                  		font-size:18px;    
                  	}
                  }
				}
				.addBtn{
					min-width:66px;height:22px;font-size:12px;text-align:center;border:1px solid map-get($theme, grey-c3c);
					color:map-get($theme, black-505);display:inline-block;border-radius: 20px;padding:2px 9px 2px 5px;   
					i{font-size:15px;} 
					span{display:inline-block;} 
					&.addpoll{
						min-width:80px;
					}
					&.content{
						margin-left: 10px;
						font-size: 18px; height: auto;
					}
					&.cancelBtn{
						padding-right: 10px; padding-left: 10px;
						.icon-block{
							font-size: 11px; margin-right: 6px;
						}
					}
				}
				&:not(:last-child){
					margin-right: 0px;
				}
				.dropdown-toggle{
					&:after{display:none;}
				}
				.dropdown-menu{
					    min-width: 260px;padding:4px 2px 4px 6px;border-color:map-get($theme, grey-c3c);
						/*left:50% !important;transform:translateX(-50%) !important;*/ margin-top:10px;z-index:10;  
						    transform: translateX(-50%) !important;top: inherit !important;left: 50% !important;          
					.dropdown-item{
						display:inline-block;padding:0;width:auto;margin-bottom:4px;background-color:transparent;  
						&:nth-child(8){margin-right: 0px;}  
						&:not(:last-child){
							margin-right:5px; 
						}
					}
					&.arrowDropdown{
						min-width:110px;padding:6px 2px 6px 6px; 
						.dropdown-item{
							margin-bottom: 0px; 
						}  
					}
					&.tableDropdown{
						padding:2px 0px 1px 2px;min-width:160px;height:160px; /*margin-top: 60px;*/ 
					  >div{
							background-color:transparent !important;padding:2px !important;
							// .htMKZA{
							// 	height:20px !important;width:20px !important;
							// }
							> div{
								.dShhsc[style*="border"]{
									background-color:#edf0f4 !important;
									border-color:#c3cad6 !important;
									// height:16px !important;width:16px !important;
									border-radius:2px !important;
								}
								.dShhsc[style*="background-color"]{
									background-color:#e3cef2 !important;  
									border-color:#b691c9 !important;
								}
							}
						}
					} 
					&.colordropdown{
						border:0px;padding:0px;min-width:auto;
					}
					&.range{
						padding:0;min-width:160px;border-radius:30px;height:40px; 
						form{
                            .slidecontainer {
							  width: 100%; 
							    .slider {
								  -webkit-appearance: none;appearance: none;width: 100%;height:2px;background: map-get($theme, grey-c3c);
								  outline: none;opacity:1;@include transition(all 0.3s);
								  position: absolute;top:19px;width: 135px;left: 12px; 
								}
								  .slider::-webkit-slider-thumb{ 
								  	 -webkit-appearance: none;appearance: none;border-radius:50%;width:8px;height:8px;
									  background: map-get($theme, black-505);cursor: pointer; 
								  }
								.slider::-moz-range-thumb{
								  -webkit-appearance: none;appearance: none;border-radius:50%;width:8px;height:8px; 
								  background: map-get($theme, black-505);cursor: pointer;
								}

							}
						}
					}
				
				}
				.textdropdown{ 
						min-width:365px;border:1px solid map-get($theme, grey-c3c);border-radius:40px; 
						transform:inherit !important;padding:3px 15px;position: absolute;left:auto !important;right:0px;
						margin-top:12px;z-index:999;background-color:map-get($theme, white); 
						display: none;
						ul{
							li{ 
								select{
									height:25px;width:45px;position: relative;top: 5px;  
								}
								a{
									padding:3px;border-radius:2px;
								}
								&:not(:last-child){
									margin-right:16px; 
								}
								&.selectfont{
									height:33px;
								}
								&.borderleft{
									position:relative;
									&:before{
										content:"";position:absolute;left:-9px;width:1px;height:calc(100% + 7px);
										background-color:map-get($theme, grey-c3c);top:-4px;
									}
								}
							}
						}
					}
				&.inputColor{
					position: relative;
					input[type="color"]{
						position: absolute;height: 100%;width: 100%;opacity: 0;cursor: pointer;
					}
				 .colordropdown{
					 margin-top:12px;
				 }
				}         

			}
		}
		&__count{
			font-size:20px;  
		}
		
		&__left{
          margin-right:105px; 
          &--mr160{
          	margin-right:160px; 
          }
		}

		@media (max-width:1599px){
			ul{
				li{
					a{
						padding:12px 10px;
						i{font-size: 24px;}  
					}
				}
			}
		}
		 @include media-breakpoint-down(lg){
           ul{
				li{
					a{
						padding:10px 8px;
						i{font-size:22px;}  
					}
					.headerTooltip{
						display:none;
					}
				}
			} 
			&__left{
				margin-right:45px; 
			}
			&__count{
				font-size: 16px; 
				span{
					margin-top: 4px;display: inline-block;
				}
			}
		 }
		//  @include media-breakpoint-down(md){
		// 	 flex-wrap:wrap;
		// 	 &__middle{
		// 		 margin-left:auto;padding-right: 10px;
		// 	 }
		// 	 &__right{
		// 		 width:100%;border-top:1px solid map-get($theme, grey-c3c);
		// 		 ul{
		// 			 display:flex;justify-content:space-between;
		// 		 }
		// 	 }
		//  }
	}
	@include media-breakpoint-down(md){
		display:none;
		//  position: absolute;top: 0px;left: 200px;width: calc(100% - 200px);
		// 		background-color:transparent !important;
        //         .headerWrapper{
        //                 box-shadow: none;padding: 5px 10px;
        //             &__left{
        //                 display:none;
        //             }
        //             &__right{
        //                 margin-right:0 !important;
        //             }
        //         }
	}
}