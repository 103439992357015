.homePage{
    .mainWrapper{ 
        display:flex;height:calc(100vh - 50px); 
        @include media-breakpoint-up(lg){
            overflow: hidden;
        } 
        &__left{
            max-width:330px;flex:0 0 330px;height:100%;position:relative;z-index:1000;
            background-color:#edf0f4; border:1px solid map-get($theme, grey-b9c);
        }
         &__right{
                flex: 0 0 auto;width:calc(100% - 330px);max-width: 100%;height:100%;position:relative;  
                // overflow:hidden;  
             .white-board{
               border: 4px solid #d7dbe0;border-bottom:0px;background-color:map-get($theme, 50); 
            //    height:calc(100vh - 207px);
               height:calc(100vh - 51px); 
               @include transition(all 0.3s);   position: relative;
               .board-inner{
                    border:1px solid map-get($theme, grey-b9c); height: 100%; 
                    // padding:0px 0px 90px;
                     position: relative;
                    .canvasbox {
                        max-height: 100%;
                        height: 100%; 
                        position: relative;
                        .fb-video-hide,
                        .youtube-video-hide,
                        .web-site-hide{
                            &:after{
                                content:"";position:absolute;top:0px;left:0px;height:100%;width:100%;background-color:transparent;
                            }
                        }

                        .fb-video{
                            .fb-speaker{
                                height: 40px;width: 40px;background-color: #fff;color: #50555a;
                                font-size: 26px;position: absolute;transform: translateX(-50%);
                                bottom: 20px;right: 20px;padding: 7px 0;
                            }
                        }
                        
                    }
                     .canvas-container{
                      margin: auto;background-color:map-get($theme, white);
                      
                      @include media-breakpoint-down(md){
                          .lower-canvas{
                            touch-action: manipulation !important;
                          }
                          .upper-canvas{
                            pointer-events: none !important;
                          }  
                       }
                       
                     }
                }
               
               canvas{
                z-index: 8;
               }
               twitter-widget{
                   margin:10px auto !important;position:absolute !important;top:0;left:50%;
                   transform:translateX(-50%) !important;

               }
               iframe, .fb-video, .canvas-background-media{
                position: absolute;height: 100%;width: 100%;border: 0px;top: 0;
                bottom: 0;
                left: 0;
                right: 0;
               }    
               .canvas-background-media{width: auto; height: auto; display: block; margin: 0 auto;
                // max-height: 100%;
            }
              
             }
             @import "components/slide-control";
             @import "components/request-time";
             @import "components/user-video";
             @import "components/admin-chat";
                 // multiple video audio
            //    .multipleStream{
            //        position: absolute;top: 0px;background: #fff;width: auto;z-index: 101;height:auto; 
            //        padding: 10px; 
                .multipleStream__left{
                    position:absolute;top:10px;left:10px;z-index: 101;
                    .userVideo{
                         position: relative !important;top:initial;left:initial; 
                    }
                    .requestTime{
                        width:210px;position:relative  !important;margin-bottom: 15px; 
                        bottom:initial;left:initial; 
                    }
                }
                .multipleStream__right{
                    position:absolute;top:10px;right:20px;z-index: 101;
                    .userVideo{
                        position: relative !important;
                        width:130px;height:105px;margin-bottom:10px;top:initial;left:initial;  
                        img,video{
                            width:130px;height:105px; 
                        }
                        video{width:auto;}
                    }
                }
            //     &.bgTransaparent{
            //         z-index: 7;background:transparent;
            //         .userVideo{
            //              z-index: 10 !important; 
            //         }
            //     }
            //    }
        }
        @media(max-width:1599px){
            &__left{
                max-width:280px;flex:0 0 280px;
            }
            &__right{
                  width:calc(100% - 280px);
                   .multipleStream{

                &__right{
                    .userVideo{
                        img,video{
                            width:130px;height:90px; 
                        }
                    }
                }
               }
            }
        }
        @include media-breakpoint-down(lg){
            position: relative;
             &__left{
                max-width:250px;flex:0 0 250px;
            }
            &__right{
                  width:calc(100% - 250px); 
            }
        }
        // @include media-breakpoint-down(md){
        //      &__left{
        //         max-width:100%;flex:0 0 100%;position: absolute;left:-100%;top:0px;width:100%;
        //         z-index:999;@include transition(all 0.3s); 
        //     }
        //     &__right{
        //           width:100%; 
        //     }
        // }
    }
    
}