.bootstrap-select {
	height:auto; // btn
    .dropdown-toggle {
        font-size:16px;padding:11px 12px 8px;font-family:map-get($font, hrg); 
        height:48px;border-radius: 3px;transition:none;
        color:map-get($theme, blue);background-color:transparent;
        border-color:map-get($theme, grey-c3c);
        &:hover,&:focus,&:active:focus,&:active, &:active:hover, &:not(:disabled),&:not(.disabled):active {
           background-color: transparent;outline: none !important;color:map-get($theme, black-000);box-shadow: none !important;outline: 0px auto -webkit-focus-ring-color !important;
        }
        .filter-option-inner-inner{
        	color:map-get($theme, black-505);@include text-overflow();text-align:right;  
        }

        &:after {
                border-top: 10px solid #8E9199;border-right: 7px solid transparent;border-bottom: 0;border-left: 7px solid transparent;
                border-radius: 3px;   
          }
        
        &.bs-placeholder{ 
            .filter-option-inner-inner{
                color:map-get($theme, grey-c3c);text-align:right;   
            }
        }
        
    } // when show class add

    &.form-control{z-index: initial;}

    &.show{
        .dropdown-toggle{
            border-radius:3px 3px 0 0 ;border-color: map-get($theme, grey-c3c);
        }

        .dropdown-menu {
            min-width: 100% !important;border-color: map-get($theme, grey-c3c);width: 100%;
            background-color:map-get($theme, white);margin-top: 0px; 
            padding: 0;border-top: 0;border-radius: 0 0 3px 3px;z-index: 3;
            .inner {
                li {
                    &.active,&:active,&.selected {
                        a {
                            background-color: map-get($theme, blue); color:map-get($theme, white); outline: none;
                        }
                    }
                    a {
                        padding: 5px 15px;font-size:15px;white-space: normal;
                        text-align:right;
                    }
                }
            }
        }
    }
    &.show-tick{
        .dropdown-menu{
            .inner{
                li{
                    &.selected{
                        border-bottom:1px solid rgba(112,112,112,0.12);
                    }
                }
            }
        }

    }
    // when dropup class add
    &.dropup {
        .dropdown-menu.show{
            >.inner.show{padding-top: 1px;border-top:1px solid map-get($theme, grey-c3c);} 
        }
        &.show{
            .dropdown-toggle {
                border-radius: 0 0 3px 3px;
            }
        }
        .dropdown-menu {
            padding: 0;margin-bottom: 0;
            border-bottom:0;border-top:0;
            border-radius: 3px 3px 0 0;
            
        }
    }

    @include media-breakpoint-down(md) {
        .dropdown-toggle {
           height: 40px; padding:8px 15px;
           font-size: 14px;
           &:after{
                font-size:7px;
           }
           +.dropdown-menu {
                .inner {
                    li {
                        a{font-size:14px;} 
                    }
                }
            }
        }
    }
    &.phone-field{
        .dropdown-toggle{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }   
    }
}
