.setting-modal{
	.modal-body{
		.nav-tabs{
			margin-bottom:40px;   
			.nav-item{
				.nav-link{
					font-size:15px;font-family:map-get($font, hrg);color:#777d84;background-color:transparent;
					border:0px;border-bottom:2px solid transparent;padding:7px 15px 5px;
					&.active{
						border-bottom-color:map-get($theme, black-2b3);color:map-get($theme, black-2b3);
						font-family:map-get($font, neo-md);
					}
				}
				&:not(:last-child){
					margin-left:20px;  
				}
			}
		}
		.col-6{
			max-width:250px;flex:0 0 250px;
		}
		.volumeBox{
			img{height:165px;} 
			path{
				stroke-linecap: square;
				stroke: white;
				stroke-width: 0.5px;
				}

				svg{
					display: block;
					width: 100%;
					height:165px;
					padding: 0;
					margin: 0;
				}
			.meterDiv{
				transform:rotate(-90deg);
				&__instant, &__slow, &__clip{
					width:167px;
				}
				meter{
					height:3px;margin-bottom:5px;  
					// -webkit-appearance: none;
					// -moz-appearance: none;
					// appearance: none;
					// width: 100%;
					// height: 30px;
					// background: #ccc;
				}
				// meter::-moz-meter-optimum::-moz-meter-bar { 
				// 	background:indianred;
				// }
				// meter::-moz-meter-sub-optimum::-moz-meter-bar {
				// 	background: antiquewhite;
				// }
				// meter::-moz-meter-sub-sub-optimum::-moz-meter-bar {
				// 	background: steelblue;
				// }
				//   meter::-webkit-meter-optimum-value {
				// 	background: map-get($theme, blue);
				// 	}
				// 	meter::-moz-meter-bar { /* Firefox Pseudo Class */
				// 	background: map-get($theme, blue);
				// 	}
				~.form-group{
					margin-top:-41px; 
					select{
						option{
						    white-space:normal; 
						}
					} 
				}
			}
		}
		.form-group{
			margin-top:15px; 
		}
		.presentationBox{
			&__box{
                height:165px;background-color:map-get($theme, black-2b3);border-radius:4px; 
                img{
                	height:100%;
                }
			}
		}
       
       .connectionDetail{
          i{font-size:40px;color:#00C674;}
          h5{font-size:16px;color:map-get($theme, black-505);margin:10px 0;}  
          p{font-size:14px;color:#777d84;margin-bottom: 8px;} 
       }

		.connectionList{
			li{
				position: relative;
				&:after{
					content:"";border-bottom:1px dashed map-get($theme, grey-c3c);position: absolute;bottom:-6px;
					right:50px;width:calc(100% - 50px); 
				}
				.custom-radio{
					label{cursor:default;}
					.custom-control-input:checked ~ label:before{
							content:"\e972";color:#00C674;font-size:21px;
					}
					.custom-control-input.errorIcon ~ label:before{
						content:"\e973";color:#D81A1A;font-size:21px;
					}
				}
				&:not(:last-child){
					margin-bottom:26px;  
					.custom-radio{
					    label{
							
					    	p{
					    		border-bottom:1px dashed map-get($theme, grey-c3c);padding-bottom:10px;
					    	}
						}
				    }
				}

			}
		}
	}
}