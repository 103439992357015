.add-modal{
	.modal-header{
		border-color:map-get($theme, grey-b9c);
	}

	.modal-body{
		ul{
			display:flex;flex-wrap:wrap;
			li{
				max-width:33.3%;flex:0 0 33.3%;border-right:1px solid map-get($theme, grey-b9c);
				border-bottom:1px solid map-get($theme, grey-b9c); position: relative;
				a{
					text-align:center;display:block;padding:15px 30px; 
					span{color:map-get($theme, black-505);font-size:15px;} 
					&:hover{
						background-color:#edf0f4;  
					}  
				}
				input[type="file"]{
					position: absolute; top: 0; width: 100%; height: 100%; opacity: 0; cursor: pointer;
				}
			}
		}
		.uploadfile{
			a{
				padding:3px 15px; 
				span{color:map-get($theme, black-505);margin-right:7px;}  
				&:hover{
					background-color:#edf0f4;   
				} 
			}
		}
	}
}

// add-video-modal
.add-video-modal{
	&.modal-dialog{ 
		.modal-content{
			.modal-body{
				padding:0 40px 25px; 
				.title{font-size:14px;color:map-get($theme, grey-777);}  
				form{
					padding-top:25px;  
					.h_60{height:60px;}
					.previewVideo{
						    margin: 25px 0 35px;   
						&__videobox{
							height:100px;min-width:150px;max-width:150px;background-color:map-get($theme, grey-dfe); 
							border-radius:4px;margin-left:20px;position:relative; 
							iframe{
								height:100%;width:100%;border:0px;  
							}
						}
						&__videodetail{
                           h3{font-size:16px;}
                           p,a{font-size:14px;color:map-get($theme, grey-777);margin-bottom:5px;line-height:22px;word-break: break-all;} 
                           .link{
                           	margin-top: 10px;
                           	&:hover{
                           		color:map-get($theme, blue); 
                           	}
                           }  
						}
					} 
				}
			}
	 	}
	}
}


// add poll modal
.add-poll-modal{
	.modal-body{
		.question{font-size:18px;margin:35px 0;} 
		// .optionList{
		// 	max-height:400px;overflow-y:auto;
		// } 
		.option{
			margin-bottom:30px;  
           h5{font-size:16px;color:map-get($theme, black-505);} 
			.progress{
			background:transparent;height:24px;border-radius:2px;margin-top:10px;  
				.progress-bar{
					background:map-get($theme, blue);font-family:map-get($font, hbd);font-size:14px;border-radius:2px; 
				}
			}	
		 } 
		
		.addOption{
			max-height:396px;overflow-x:auto;  
			.form-group{ 
				padding-left: 50px;position:relative;
				.form-control{
					height:50px !important;   
				}
				.delete{
                      height:34px;width:34px;border-radius:50%;background:map-get($theme, white);display:block;border:1px solid map-get($theme, grey-b9c);
                      color:map-get($theme, grey-777 );font-size:20px;position:absolute;top:9px;left:10px;text-align: center; 
    				  padding:4px 0;
				}
			}
			@media(max-width:199px){
				max-height:320px; 
			}
		}
	}
}

.add-library{
	.modal-header{
		border-color:map-get($theme, grey-b9c);
	}
	.modal-body{
		// padding-bottom: 0 !important;
		border-radius:0px 0px 4px 4px;
		.alert-danger{
			margin:0 12px;
		}
		.searchSection{
			position:relative;
			.form-group{								
				.form-control{				
					@include transition(all 0.3s);
					opacity: 0;
				}				
				.closeSearch,
				.openSearch{
				   font-size: 19px;color:map-get($theme, black-505);@include absolutecenter(y);
				   left:10px;height: 19px; @include transition(all 0.3s);
				}
				.closeSearch{
					font-size: 22px;
					height: 22px;
					margin-top: -2px;
					left: 7px;
					opacity: 0;
					z-index: 0;
				}
				.openSearch{
					z-index: 1;
				}
				&.show{
					z-index: 1;
					.closeSearch,
					.form-control{
						opacity: 1;						
					}
					.closeSearch{
						z-index: 1;
					}
					.openSearch{
						opacity: 0;
						z-index: 0;
					}
				}				
			}
			.addFolder{
				font-size: 23px;@include absolutecenter(y);
				left:11px;height: 23px; @include transition(all 0.3s);	color:map-get($theme, grey-777);
			}
			.folderBackbtn{
				position: absolute;right: 10px;top: 12px;color:map-get($theme, grey-777);
				display:none;
				span{font-size:14px;}
				i{font-size:24px;margin-left: 4px;}  
			}
		}


		.libraryHead{
			li{
				.btn{padding:8px 25px;}
				&:not(:last-child){
					margin-left:20px;
				}
				.list{
					color:map-get($theme, black-505);display: inline-block;margin-top: 8px;   
					i{font-size:20px;} 
				}
			}
		}
		.folderList{
			padding: 0 5px;max-height:450px;overflow-y:auto;
			 // // Defalt scrollbar	
			//   ::-webkit-scrollbar { 
			// 	width:0px;
			// 	}
			// 	::-webkit-scrollbar-track {
			// 	-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.2);
			// 	}
			// 	::-webkit-scrollbar-thumb {
			// 	background-color:map-get($theme, grey-818); 
			// 	outline: 1px solid map-get($theme, grey-c3c); 
			// 	}		
			li{	
				margin-bottom: 10px;
				padding: 0 5px;
				-ms-flex: 0 0 50%;
				flex: 0 0 50%;
				max-width: 50%;
				position: relative;
				.folderBox{
				  color:map-get($theme, black-505); font-size:14px; padding: 10px 15px;
				  border:1px solid map-get($theme, grey-c3c);background-color:map-get($theme, white);
                  border-radius: 6px; @include box-shadow(0,2px,2px,rgba(map-get($theme, black-000), 0.1));
				  &:hover{				  	
				  		.folderIcon{						
						  	.icon-folder{						  		
						  		color:map-get($theme, black-505);				  		
						  	}
						}
				  }					  		  
				}
				.folderIcon{
					margin-left: 10px;
				  	.icon-folder{
				  		font-size: 35px;
				  		color:map-get($theme, grey-777);				  		
				  	}
				}
				.text{
					.name{
						color:map-get($theme, black-000);
					}
					.number{
						font-size: 12px;
					}
				}					
				.dropdown{
					position: absolute;
					left: 20px;				
					top: 23px;					
					a{
						color:map-get($theme, grey-777);
					}
					.dropdown-toggle{
						font-size: 20px;
						&::after{
							display: none;
						}
					}
					.dropdown-menu{
						padding: 5px 0;
						font-size: 12px;
						min-width: 124px;
						.dropdown-item{
							padding: 3px 15px;
							&:focus,&:active{
								background-color:transparent; 
							}
						}
					}
				}			
			}          
		}
		.fileList{
			padding: 0 10px;max-height:calc(100vh - 300px);
			overflow-y:auto;
			li{	
				border-bottom:1px dashed map-get($theme, grey-c3c);		
				.fileBox{
				  color:map-get($theme, black-505); font-size:14px; padding: 12px 5px 12px 15px;
				  		
				  &.active{
				  	background-color: map-get($theme, white);
				  }		 
                }
				.fileIcon{				
					margin: 0 5px 0 15px;
					font-size: 30px;
					img{min-width:30px;max-width:30px;}
				}
				.text{
					.name{
						color:map-get($theme, black-000);
					}
					.number{
						font-size: 12px;
					}
				}
				.dropdown{										
					a{
						color:map-get($theme, grey-777);
					}
					.dropdown-toggle{
						font-size: 20px;
						&::after{
							display: none;
						}
					}
					.dropdown-menu{
						padding: 5px 0;
						font-size: 12px;
						min-width: 124px;
						.dropdown-item{
							padding: 3px 15px;
							&:focus,&:active{
								background-color:transparent;  
							}
						}
					}
				}
				.fileProgress {
					position: relative;
					label{
						font-size: 12px;
					}
					.progressSec{						
						.progress{
							width: 200px; height: 8px; 
							background-color: #ced4db;
							.progress-bar{
								background:map-get($theme, blue);
							}
						}
					}
					.actionButton {					   					  
					    margin: 6px 0 0 -10px;
					    font-size: 24px;
					    cursor: pointer;
					    color:map-get($theme, black-505);
					}
					.fileUploading,
					.fileUploadingFailed{
						opacity: 0;
						// position: absolute;
						// left: 0;
						// top: -24px;
						margin-bottom:15px;
						@include transition(all 0.3s);
						.progressSec{
							margin-right: 50px; 
						}
					}
					.fileUploadingFailed{ 
						opacity: 1;									
						label{
							color: #ef8508;
							min-width: 100px;
						}
					}
					&.uploading{
						.fileUploading{
							opacity: 1;
							z-index: 1;
						}
						.fileUploadingFailed{
							opacity: 0;
							z-index: 0;
						}
					}
				}
				&.fileInProcess{
					.custom-control,.fileIcon,.text {
						opacity: 0.4;
					}
				}
			}
		}
		.btn-row {
			padding: 0 20px;
			[class^="icon-"], [class*=" icon-"]{
				font-size: 24px;
				margin-top: -5px;
			}
			.btn {
				 &.btn-dark{
				 	&[disabled="disabled"]{
					 	 border-color:map-get($theme, grey-b9c);
					 	 background-color:map-get($theme, grey-b9c);
					 	 cursor: not-allowed;
					 }
				 }
			}
		}
	}
}