.requestTime{
               background-color:map-get($theme, white);
               padding:10px 15px 10px 10px;width:250px;border-radius:4px; 
               border:1px solid map-get($theme, grey-c3c);
               position:absolute;bottom:15px;left:15px;z-index:9;  
                .progress{
                 height:6px;border-radius:0 0 4px 4px;background-color:map-get($theme, grey-c3c); 
                position: absolute;bottom:-1px;width: calc(100% + 2px);left:-1px; 
                 .progress-bar{
                   background-color:map-get($theme, blue);border-radius:0 0 4px 0px;  
                 }
               }
            //    &:after{
            //     content:"";position:absolute;bottom:-6px;right:0px;width:75px;height:6px;
            //     background-color:map-get($theme, blue);border-radius:0 0 4px 0px;   
            //    }   
               li{
                     color:map-get($theme, black-2b3);
                    a{
                        color:map-get($theme, black-2b3);
                        i{font-size:26px;} 
                    }
                    span{display:block;font-size:15px;}  
                    h4{font-size:15px;}
                    &:not(:last-child){
                        margin-right:7px;   
                    }
                }
             }