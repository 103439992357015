@mixin center-block() {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@mixin text-overflow() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin clearfix() {
    &::after {
        display: block;
        clear: both;
        content: "";
    }
}

@mixin resizable($direction) {
    resize: $direction; // Options: horizontal, vertical, both
    overflow: auto; // Per CSS3 UI, `resize` only applies when `overflow` isn't `visible`
}

@mixin backface-visibility($visibility) {
    -webkit-backface-visibility: $visibility;
    -moz-backface-visibility: $visibility;
    backface-visibility: $visibility;
}

@mixin appearance() {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
}

@mixin no-outline() {
    outline: none;
    -moz-outline: none;
    -webkit-outline: none;
    -ms-outline: none;
    -o-outline: none;
}

@mixin animation ($animation, $duration, $transition, $iteration){
  -webkit-animation-name: $animation;
  -webkit-animation-duration: $duration;
  -webkit-animation-timing-function: $transition;
  -webkit-animation-iteration-count: $iteration;
  -moz-animation-name: $animation;
  -moz-animation-duration: $duration;
  -moz-animation-timing-function: $transition;
  -moz-animation-iteration-count: $iteration;
  -o-animation-name: $animation;
  -o-animation-duration: $duration;
  -o-animation-timing-function: $transition;
  -o-animation-iteration-count: $iteration;
  animation-name: $animation;
  animation-duration: $duration;
  animation-timing-function: $transition;
  animation-iteration-count: $iteration;
}


@mixin box-shadow($top, $left, $blur, $color) {
   
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow:  $top $left $blur $color;
    box-shadow:  $top $left $blur $color;
}

@mixin absolutecenter($axis: "both"){
  position:absolute;
  @if $axis == "y"{
    top: 50%;
    transform: translateY(-50%);
  }
  @if $axis == "x"{
    left: 50%;
    transform: translateX(-50%);
  }
  @if $axis == "both"{
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
}

    


@mixin hyphens($mode: auto) {
    word-wrap: break-word;
    -webkit-hyphens: $mode;
    -moz-hyphens: $mode;
    -ms-hyphens: $mode; // IE10+
    -o-hyphens: $mode;
    hyphens: $mode;
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}  
}

@mixin scale($ratio...) {
    -webkit-transform: scale($ratio);
    -ms-transform: scale($ratio);
    -o-transform: scale($ratio);
    transform: scale($ratio);
}

@mixin scaleX($ratio) {
    -webkit-transform: scaleX($ratio);
    -ms-transform: scaleX($ratio); // IE9 only
    -o-transform: scaleX($ratio);
    transform: scaleX($ratio);
}

@mixin scaleY($ratio) {
    -webkit-transform: scaleY($ratio);
    -ms-transform: scaleY($ratio); // IE9 only
    -o-transform: scaleY($ratio);
    transform: scaleY($ratio);
}

@mixin skew($x, $y) {
    -webkit-transform: skewX($x) skewY($y);
    -ms-transform: skewX($x) skewY($y);
    -o-transform: skewX($x) skewY($y);
    transform: skewX($x) skewY($y);
}

@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -ms-transform: translate($x, $y); // IE9 only
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}

@mixin translate3d($x, $y, $z) {
    -webkit-transform: translate3d($x, $y, $z);
    transform: translate3d($x, $y, $z);
}

@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -ms-transform: rotate($degrees); // IE9 only
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}

@mixin rotateX($degrees) {
    -webkit-transform: rotateX($degrees);
    -ms-transform: rotateX($degrees); // IE9 only
    -o-transform: rotateX($degrees);
    transform: rotateX($degrees);
}

@mixin rotateY($degrees) {
    -webkit-transform: rotateY($degrees);
    -ms-transform: rotateY($degrees); // IE9 only
    -o-transform: rotateY($degrees);
    transform: rotateY($degrees);
}



@mixin transform-origin($origin) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -ms-transform-origin: $origin; // IE9 only
    transform-origin: $origin;
}

@mixin transition($transition...) {
    -webkit-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin transition-property($transition-property...) {
    -webkit-transition-property: $transition-property;
    transition-property: $transition-property;
}

@mixin transition-delay($transition-delay) {
    -webkit-transition-delay: $transition-delay;
    transition-delay: $transition-delay;
}

@mixin transition-duration($transition-duration...) {
    -webkit-transition-duration: $transition-duration;
    transition-duration: $transition-duration;
}

@mixin transition-timing-function($timing-function) {
    -webkit-transition-timing-function: $timing-function;
    transition-timing-function: $timing-function;
}

@mixin transition-transform($transition...) {
    -webkit-transition: -webkit-transform $transition;
    -moz-transition: -moz-transform $transition;
    -o-transition: -o-transform $transition;
    transition: transform $transition;
}

@mixin user-select($select) {
    -webkit-user-select: $select;
    -moz-user-select: $select;
    -ms-user-select: $select; // IE10+
    user-select: $select;
}


@mixin selection {
    & ::selection {
        @content;
    }
    & ::-moz-selection {
        @content;
    }
}

@mixin flexcenter($axis: "both"){
  display: flex;
  @if $axis == "y"{
    align-items: center;
  }
  @if $axis == "x"{
    justify-content: center;
  }
  @if $axis == "both"{
    justify-content: center;
    align-items: center;
  }
}


//button mixin 
@mixin button-variant($color, $background, $border) {
    color: $color;
    background-color: $background;
    border-color: $border;

    &:focus,
    &.focus {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 25%);
    }
    &:hover {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 12%);
    }
    &:active,
    &.active,
    .open>&.dropdown-toggle {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 12%);

        &:hover,
        &:focus,
        &.focus {
            color: $color;
            background-color: darken($background, 17%);
            border-color: darken($border, 25%);
        }
    }
    &:active,
    &.active,
    .open>&.dropdown-toggle {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus,
        &.focus {
            background-color: $background;
            border-color: $border;
        }
    }

    .badge {
        color: $background;
        background-color: $color;
    }
}

@mixin button-outline-variant($color, $color-hover: #fff) {
    color: $color;
    background-color: transparent;
    background-image: none;
    border-color: $color;

    @include hover {
        color: $color-hover;
        background-color: $color;
        border-color: $color;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($color, .5);
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active,
    .show>&.dropdown-toggle {
        color: $color-hover;
        background-color: $color;
        border-color: $color;
        box-shadow: 0 0 0 $input-btn-focus-width rgba($color, .5);
    }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
    @include border-radius($border-radius);
}

//breakpoints
$grid-breakpoints: ( xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px);

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min !=0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
    $next: breakpoint-next($name, $breakpoints);
    @return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints)==null, "", "-#{$name}");
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    }
    @else {
        @content;
    }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
        @media (max-width: $max) {
            @content;
        }
    }
    @else {
        @content;
    }
}

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($lower, $breakpoints);
    $max: breakpoint-max($upper, $breakpoints);

    @if $min !=null and $max !=null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    }
    @else if $max==null {
        @include media-breakpoint-up($lower) {
            @content;
        }
    }
    @else if $min==null {
        @include media-breakpoint-down($upper) {
            @content;
        }
    }
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    $max: breakpoint-max($name, $breakpoints);

    @if $min !=null and $max !=null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    }
    @else if $max==null {
        @include media-breakpoint-up($name) {
            @content;
        }
    }
    @else if $min==null {
        @include media-breakpoint-down($name) {
            @content;
        }
    }
}

// keyframes mixin

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; 
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  } 
}



// media screen

@mixin desktop-screen-1600{
    @media (min-width:1600px) and (max-width:1680px){
        @content;
    }
}

@mixin laptop-screen-1200{
    @media (min-width:1200px) and (max-width:1440px){
        @content;
    }
}

 

//font family
 @mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;

    @if $asset-pipeline == true {
      src: font-url('#{$file-path}.eot');
      src: font-url('#{$file-path}.eot?#iefix')          format('embedded-opentype'),
           font-url('#{$file-path}.woff')                format('woff'),
           font-url('#{$file-path}.ttf')                 format('truetype'),
           font-url('#{$file-path}.svg##{$font-family}') format('svg');
    } @else {
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix')               format('embedded-opentype'),
           url('#{$file-path}.woff')                     format('woff'),
           url('#{$file-path}.ttf')                      format('truetype'),
           url('#{$file-path}.svg##{$font-family}')      format('svg');
    }
  }
}
//line-scale

@mixin global-animation() {
  animation-fill-mode: both;
}

@mixin line-scale($n:5) {
  @for $i from 1 through $n {
    > div:nth-child(#{$i}) {
      animation: line-scale 1s delay(0.1s, $n, $i) infinite cubic-bezier(.2,.68,.18,1.08);
    }
  }
}


// This following mixin is modified from the original Bourbon triangle add-on
// (https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/addons/_triangle.scss)
// in order to customise triangle shape and foreground / background colours, which can also create CSS 
// ribbon incredibly easy.

@mixin triangle ($size, $color, $direction) {
  height: 0;
  width: 0;

  $width: nth($size, 1);
  $height: nth($size, length($size));

  $foreground-color: nth($color, 1);
  $background-color: transparent !default;
  @if (length($color) == 2) {
    $background-color: nth($color, 2);
  }

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {

    $width: $width / 2;

    @if $direction == up {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-bottom: $height solid $foreground-color;

    } @else if $direction == right {
      border-top: $width solid $background-color;
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;

    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;

    } @else if $direction == left {
      border-top: $width solid $background-color;
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
    }
  }

  @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;

    @if $direction == up-right {
      border-left:  $width solid $background-color;

    } @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  }

  @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left:  $width solid $background-color;

    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  }

  @else if ($direction == inset-up) {
    border-width: $height $width;
    border-style: solid;
    border-color: $background-color $background-color $foreground-color;
  }

  @else if ($direction == inset-down) {
    border-width: $height $width;
    border-style: solid;
    border-color: $foreground-color $background-color $background-color;
  }

  @else if ($direction == inset-right) {
    border-width: $width $height;
    border-style: solid;
    border-color: $background-color $background-color $background-color $foreground-color;
  }

  @else if ($direction == inset-left) {
    border-width: $width $height;
    border-style: solid;
    border-color: $background-color $foreground-color $background-color $background-color;
  }
}