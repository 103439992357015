 .slideControl {
                    position: absolute;
                    right: 0;
                    bottom:175px;
                    left: 0; 
                    z-index: 100;
                    @include transition(all 0.3s); 
                    .pagination{
                        max-width: 356px;
                        background-color: map-get($theme, white);                  
                        @include box-shadow(0,1px,2px,rgba(map-get($theme, black-000), 0.2));
                        border-radius: 40px;
                        .page-item{
                            &:first-child {
                                .page-link{
                                    border-top-left-radius: 40px;
                                    border-bottom-left-radius: 40px;
                                }
                            }
                            &:last-child {
                                .page-link{
                                    border-top-right-radius: 40px;
                                    border-bottom-right-radius: 40px;
                                }
                            }
                            .page-link{
                                padding:4px 8px 3px; min-height: 41px;
                                color:map-get($theme, black-505);   
                                font-size: 15px;
                                &.disabled{
                                    pointer-events: none;opacity: 0.8;
                                }
                                 
                                [class^="icon-"], 
                                [class*=" icon-"]{
                                    cursor: pointer;
                                }                                                           
                                [class^="icon-arrow"], 
                                [class*=" icon-arrow"]{
                                    min-width: 32px;
                                    min-height: 32px;
                                    display: inline-block;                                   
                                    line-height: 32px;
                                    text-align: center;
                                    border-radius: 4px;
                                }
                                &:hover,&.active{
                                    background-color: transparent;
                                    [class^="icon-arrow"], 
                                    [class*=" icon-arrow"]{
                                        color:map-get($theme, blue);
                                        background-color:rgba(map-get($theme, blue), 0.15);                                    
                                    }
                                }
                                &.slideInfo{
                                    min-width: 70px;
                                    font-family:map-get($font, neo-md); 
                                    padding-top: 13px;                           
                                   
                                }
                                &.zoomSlider{ 
                                    font-size: 24px;                                   
                                    .customRange{
                                         -webkit-appearance: none;
                                          width: 110px;
                                          height: 1px;
                                          background-color: map-get($theme, grey-b9c); 
                                          outline: none;                                          
                                        &::-webkit-slider-thumb {
                                          -webkit-appearance: none;
                                          appearance: none;
                                          width: 3px;
                                          height: 12px;                                             
                                          background-color: map-get($theme, black-505);
                                          cursor: pointer;
                                          border-radius: 0;
                                          border: none;
                                        }
                                        &::-moz-range-thumb { 
                                         appearance: none;                                          
                                          width: 3px;
                                          height: 12px; 
                                          border-radius: 0;                                                                                      
                                          background-color: map-get($theme, black-505);
                                          cursor: pointer;
                                          border: none;                                          
                                        }
                                    }
                                }
                            }
                           
                        }
                    }
                }