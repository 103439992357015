.extendtime-modal{
   .modal-body{
   	  .timeList{
   	  	label{
   	  		display:inline-block;margin-bottom:15px; 
   	  		span{
   	  			color:map-get($theme, blue);font-family:map-get($font, neo-md);font-size:14px;
   	  			min-width:110px;border:1px solid #d0cad9;display:block;cursor:pointer; 
   	  		   background-color:map-get($theme, white);padding:11px 10px;border-radius:4px;margin-bottom:0px; 
   	  		}
          &:hover{
              span{
               background-color: #f5f2fa;
              }
          }
   	  		input[type="radio"]{
   	  			&:checked{
   	  				~span{
                        background-color:map-get($theme, blue);color:map-get($theme, white);  
   	  				}
   	  			}
   	  		}
           &:not(:last-child){
           	margin-left:8px;  
		   }
		   &.disabled{
				 pointer-events: none;opacity:0.5;
		   }
   	  	}
   	  }
   	  .messageLink{
   	  	font-size:14px;color:map-get($theme, grey-777);margin:25px 0 5px;     
   	  	span{margin-right:10px;display: inline-block;vertical-align: top;text-decoration:underline;}
   	  	i{font-size:23px;} 
   	     
   	  }
   }
}